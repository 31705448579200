import { Row, Col, Button, Stack } from 'react-bootstrap';
import { BrowserView, MobileView } from 'react-device-detect';
import { ThLoading } from 'src/components/elements';
import SurveyListItem from './SurveyListItem';
import { SubmissionStatus } from './types';
import { useSurveyContext } from '../../state/surveyContext';

function SurveyList() {
  const {
    surveyHistory,
    totalPages,
    isLoading,
    isFetching,
    isRefetching,
    currentPage,
    setCurrentPage,
  } = useSurveyContext();

  if (isLoading && !surveyHistory && isRefetching) {
    return <ThLoading />;
  }
  const handlePageChange = (newPage: number) => {
    setCurrentPage(newPage);
  };

  return (
    <>
      <BrowserView>
        <div className="mt-3 ms-4 me-3">
          <h3 className="title-section">
            Completed Surveys, Status & Redemptions
          </h3>
          <p>
            Surveys can take the facilitator up to 60 days to approve. Once
            approved, instantly redeem for 140+ online gift cards to well known
            retailers, like Starbucks, Chipotle, Target, Walmart, Home Depot,
            and more, or redeem for Trusted Herd In-Network membership! You must
            have at least $5 in your survey bank to redeem.
          </p>
          <div className="d-flex gap-5 justify-content-end me-5">
            <span className="title-section__description">Submission</span>
            <span className="title-section__description">Survey</span>
          </div>
          <Row>
            {surveyHistory?.map((survey) => (
              <Col key={survey.id} xs={12}>
                <SurveyListItem
                  title={`Survey ${survey.surveyId || survey.mid}`}
                  reward={survey.surveyData?.rpi || survey.surveyData?.c || 0}
                  date={survey.createdAt}
                  status={survey.surveyStatus as SubmissionStatus}
                  callbackReceived={survey.callbackReceived}
                  callbackStatus={survey.callbackStatus}
                />
              </Col>
            ))}
            {totalPages > 1 && (
              <div className="d-flex align-items-center justify-content-center mt-3 mb-3">
                <Button
                  variant="primary"
                  onClick={() => handlePageChange(currentPage - 1)}
                  disabled={currentPage === 1 || isFetching}
                >
                  Previous
                </Button>
                <span className="mx-3">
                  Page {currentPage} of {totalPages}
                </span>
                <Button
                  variant="primary"
                  onClick={() => handlePageChange(currentPage + 1)}
                  disabled={currentPage === totalPages || isFetching}
                >
                  Next
                </Button>
              </div>
            )}
          </Row>
        </div>
      </BrowserView>
      <MobileView>
        {surveyHistory?.map((survey) => (
          <SurveyListItem
            key={survey.id}
            title={`Survey #${survey.surveyId || survey.mid}`}
            reward={survey.surveyData?.rpi || survey.surveyData?.c || 0}
            date={survey.createdAt}
            status={survey.surveyStatus as SubmissionStatus}
            callbackReceived={survey.callbackReceived}
            callbackStatus={survey.callbackStatus}
          />
        ))}
        {totalPages > 1 && (
          <Stack
            direction="horizontal"
            className="align-items-center justify-content-between my-3"
          >
            <Button
              variant="primary"
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1 || isFetching}
              style={{ flex: '1 1 0' }}
            >
              Previous
            </Button>
            <span className="mx-3">
              Page {currentPage} of {totalPages}
            </span>
            <Button
              variant="primary"
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === totalPages || isFetching}
              style={{ flex: '1 1 0' }}
            >
              Next
            </Button>
          </Stack>
        )}
      </MobileView>
    </>
  );
}

export default SurveyList;
